.App {
  max-width: 540px;
  box-shadow: 0 0 48px 0 rgba(0,0,0,.2);
  margin-right: auto;
  margin-left: auto;
  min-height: 100vh;
  position: relative;
  background: rgb(249,250,251);
  background: linear-gradient(180deg, rgba(249,250,251,1) 0%, rgba(249,250,251,1) 33%, rgba(228, 244, 240,1) 100%);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

a {
  text-decoration: none;
}

.input-datepicker {
  width: 100%;
}

.pooper-datepicker {
  z-index: 1;
}

.footer-menu {
  position: fixed;
	width: inherit;
}

.slider-arrow {
  position: absolute;
  top: 30%;
}

.ctrl-right {
  right: 0;
}

.ctrl-left {
  left: 0;
}

.history-title {
  /* min-width: 100px; */
}
.timeline-item-title {
  text-align: center !important;
}
.timeline-card-content > .card-description {
  align-items: start;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Gaya CSS untuk layar kecil */
@media screen and (max-width: 768px) {
  .App {
    width: 100%;
  }
  .error-message {
    width: 100%;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* https://dl.dropboxusercontent.com/s/tb217skv4ol39hb/penguin-flat.png */

body {
  background-color: #fff;
}
.error-message {
  width: 500px;
  position: relative;
  overflow: hidden;
  margin: 80px auto 0;
  font-family: 'Open Sans', sans-serif;
  background: transparent url(https://dl.dropboxusercontent.com/s/60xw1ngn6u8t4dx/error_penguin-bg.png?dl=0) no-repeat;
  background-size: contain;
  background-position: 30% 0;
  max-width: 600px;
}
.error-message h1, .error-message h2, .message-body {
  color: #0085CA;
}
.message-body {
  font: normal 400 #0085CA;
  margin-top: 10px;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
}
.error-message h1 { 
  font-weight: 800;
  font-size: 100px;
  line-height: 90px;
  margin: 0 0 0 0;
  padding: 0;
}
.error-message h2 {
  font-weight: 800;
  font-size: 50px;
  line-height: 35px;
  margin: 0 0 10px 0;
}
.message {
  font-size: 18px;
  font-weight: 400;
  height: 192px; 
  max-width: 350px;
  padding-left: 50%;
}

textarea.myTextArea {
  width: 100%;
  height: 150px;
  padding: 10px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 14px;
  resize: none;
}

textarea.myTextArea.focus-visible {
  border-color: rgb(8, 145, 178);
}

/* szh-accordion */
.szh-accordion {
  width: 100%;
}

.szh-accordion .szh-accordion__item-btn {
  width: 100%;
  overlay: none;
  border: none;
  padding: 0;
  background:none;
}

.szh-accordion .szh-accordion__item {
  box-shadow: rgba(0, 0, 0, 0.23) 0px 2px 2.62px;
  margin-bottom: 16px;
}
/* end szh-accordion */

@media (max-width: 600px){
  
  .error-message {
    width: 100%;
    max-width: 600px;
    margin: 80px auto 0;
  }
  .error-message h1 { 
    font-size: 14vw;
    line-height: 13vw;
    margin: 0 0 0 0;
  }
.error-message h2 {
    font-size: 7vw;
    line-height: 6vw;
    margin: 0 0 10px 0;
  }
  .message {
    font-size:3vw;
    padding-left: 60%;
  }
  .message a {
    display: block;
  }
}
#penguin {
  position: absolute;
  top:0;
  left: 0;
}
#penguin img:last-child {
    display: none;
}
@media (max-width: 480px){
    .message {
    font-size:3.5vw;
  }
}

@media (max-width: 480px){
    .message {
    font-size:3.75vw;
      padding-left: 65%;
  }
}
